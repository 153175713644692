'use client';

import { useEffect } from 'react';
import initBaseStyles from '@nerdwallet/base-styles/init';

export default function InitBaseStyles() {
  useEffect(() => {
    initBaseStyles();
  }, []);

  return null;
}
